module.exports = {
    siteMetadata: {
      title: `Associates | Outsmart Insight`,
      description: `Together, we help companies get smarter about the future impact of emerging technologies.`,
      author: `@outsmartinsight`,
      siteUrl: 'https://associates.outsmartinsight.com',
      twitterHandle: '@outsmartinsight',
      address: 'Outsmart HQ, London, UK',
      telephoneNo: '+44 333 002 0240',
      email: {
        contact: 'join.associates@outsmartinsight.com',
        careers: 'careers@outsmartinsight.com',
        consulting: 'join@outsmartinsight.com'
      },
      linkedInUrl: 'https://www.linkedin.com/company/outsmart-insights-ltd'
    },
    customerStories: {
        pageSize: 12
    },
    ourInsights: {
        pageSize: 12
    },
    projects: {
        pageSize: 36
    },
    pages: {
      contact: '/contact',
      cookiePolicy: '/cookie-policy',
      newsletter: '/newsletter',
      privacyPolicy: '/privacy-policy',
      careers: 'https://www.outsmartinsight.com/careers/',
      termsConditions: '/terms-conditions',
        projects: '/projects',
        associates: {
            discover: '/',
            meet: '/network',
            join: '/preregister',
            whatWeDo: '/our-approach',
            login: `${process.env.GATSBY_ASSOCIATES_URL}login`,
        },
        advisors: 'https://www.outsmartinsight.com/advisors/',
    },
    prismic: {
      accessToken: process.env.PRISMIC_ACCESS_TOKEN,
      repo: process.env.GATSBY_PRISMIC_REPO_NAME
    },
    newsletter: {
      activeCampaign: {
        companySubDomain: "",
        formId: ""
      }
    }
}