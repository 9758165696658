/**
 * Make API call to get the user's profile
 */

const isBrowser = typeof window !== 'undefined';

async function getProfile() {
    if(!isBrowser) {
        return null;
    }

    const response = await fetch(`${process.env.GATSBY_ASSOCIATES_API_URL}/me/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.localStorage.getItem('token')}`,
        },
    });

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    const error = new Error(response.statusText);
    error.response = await response.json();
    throw error;
}

export { getProfile };