import * as React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from './src/utils/prismic-previews'
import RootElement from "./src/components/root-element";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <RootElement>
      {element}
    </RootElement>
  </PrismicPreviewProvider>
)