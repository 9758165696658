import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from "../contexts/auth";

const queryClient = new QueryClient();

const RootElement = ({ children }) => {
  return (
      <QueryClientProvider client={queryClient}>
          {children}
      </QueryClientProvider>
  );
};

export default RootElement;
