import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getProfile } from "../api/auth";
import queryString from "query-string";

export const AuthContext = createContext({});

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState(null);
    const [waitingForUser, setWaitingForUser] = useState(true);
    const queryClient = useQueryClient();

    useEffect(() => {
        // Check if token is in querystring
        const parsed = queryString.parse(window.location.search);
        if (Object.prototype.hasOwnProperty.call(parsed, 'auth_token') && !token) {
            setToken(parsed['auth_token']);
            window.history.replaceState({}, document.title, window.location.pathname);

            return;
        }

        // Check if token is in local storage
        if (Object.prototype.hasOwnProperty.call(window.localStorage, 'token') && !token) {
            const tokenFromStorage = window.localStorage.getItem('token');
            setToken(tokenFromStorage);

            return;
        }


        setWaitingForUser(false);
    }, []);

    // Set token into local storage
    useEffect(() => {
        // If token is null, remove it from local storage
        if (token) {
            window.localStorage.setItem('token', token);
        }
    }, [token]);

    const { isLoading, isError } = useQuery(
        'user',
        async () => {
            try {
                const data = await getProfile();
                setUser(data);
                setIsLoggedIn(true);
                return data;
            } catch (error) {
                setIsLoggedIn(false);
                throw error;
            } finally {
                setWaitingForUser(false);
            }
        },
        {
            enabled: !!token,
            retry: false,
            // Cache the result for 24 hours
            cacheTime: 1000 * 60 * 60 * 24,
            staleTime: 1000 * 60 * 60 * 24,
        });

    const refreshUser = useCallback(() => {
        queryClient.invalidateQueries('user');
    }, [queryClient]);

    const value = {
        isLoggedIn,
        isLoading,
        isError,
        user,
        token,
        setToken,
        refreshUser,
    }

    return (
        <AuthContext.Provider value={value}>
            {!waitingForUser && children}
        </AuthContext.Provider>
    );
}